import { CONSTANTS } from "../actions";

export const addCard = (listId, content) => {
  return {
    type: CONSTANTS.ADD_CARD,
    payload: { listId, content }
  };
};
export const deleteCard = (listId, index) => {
  return {
    type: CONSTANTS.DELETE_CARD,
    payload: { listId, index }
  };
};
