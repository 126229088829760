import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { connect } from "react-redux";
import Icon from "@material-ui/core/Icon";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import Typography from "@material-ui/core/Typography";

import { addCard, addList } from "../actions";

const ActionButton = ({ dispatch, shouldAddList, listId }) => {
  const [formOpen, setFormOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const handleAction = action => {
    if (text) {
      dispatch(shouldAddList ? addList(text) : addCard(listId, text));
      setText("");
      setFormOpen(false);
    }
    return;
  };
  const renderAddButton = () => {
    const buttonText = shouldAddList ? "Add another list" : "Add another card";
    return (
      <div
        style={styles.addButton(shouldAddList)}
        onClick={() => setFormOpen(true)}
      >
        <Icon>add</Icon>
        <Typography style={{ marginLeft: 2 }}>{buttonText}</Typography>
      </div>
    );
  };
  const renderAddForm = () => {
    const placeHolderText = shouldAddList
      ? "Enter list title..."
      : "Enter a title for this card...";
    const buttonTitle = shouldAddList ? "Add List" : "Add Card";
    return (
      <div>
        <Card style={styles.addFormCard(shouldAddList)} elevation={0}>
          <TextareaAutosize
            autoFocus
            placeholder={placeHolderText}
            // onBlur={() => setFormOpen(false)}
            value={text}
            onChange={event => setText(event.target.value)}
            style={styles.addFormTextArea}
          />
        </Card>
        <div style={styles.addFormButtonGroup}>
          <Button
            disableElevation
            variant="contained"
            style={styles.addFormSubmitButton}
            // onMouseDown is used in conjuction with onBlur
            onMouseDown={handleAction}
          >
            {buttonTitle}
          </Button>
          <Icon
            style={styles.addFormCloseIcon(shouldAddList)}
            onClick={() => setFormOpen(false)}
          >
            close
          </Icon>
        </div>
      </div>
    );
  };
  return formOpen ? renderAddForm() : renderAddButton();
};
const styles = {
  addButton: shouldAddList => {
    return {
      alignItems: "center",
      borderRadius: shouldAddList ? 3 : 0,
      backgroundColor: shouldAddList ? "#979596" : "inherit",
      color: shouldAddList ? "white" : "inherit",
      cursor: "pointer",
      display: "flex",
      height: "100%",
      opacity: shouldAddList ? 0.7 : 0.5,
      padding: shouldAddList ? "14px 10px" : "6px 0",
      width: 272
    };
  },
  addFormButtonGroup: {
    alignItems: "center",
    display: "flex",
    marginTop: 8
  },
  addFormCard: shouldAddList => {
    return {
      border: shouldAddList ? "1px solid rgba(0, 0, 0, 0.4)" : "inherit",
      minHeight: 26,
      minWidth: 272,
      padding: 12,
      overflow: "visible"
    };
  },
  addFormCloseIcon: shouldAddList => {
    return {
      color: "rgba(0, 0, 0, 0.4)",
      cursor: "pointer",
      marginLeft: 8
    };
  },
  addFormTextArea: {
    border: "none",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    overflow: "hidden",
    outline: "none",
    resize: "none",
    width: "100%"
  },
  addFormSubmitButton: {
    backgroundColor: "#5aac44",
    color: "white"
  }
};

export default connect()(ActionButton);
