import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import React from "react";
import Typography from "@material-ui/core/Typography";

import ActionButton from "./ActionButton";
import BankanList from "./BankanList";
import { sort } from "../actions";

const App = ({ dispatch, lists }) => {
  const handleDragEnd = result => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    dispatch(
      sort(
        source.droppableId,
        destination.droppableId,
        source.index,
        destination.index,
        draggableId,
        type
      )
    );
  };
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="App">
        <Typography variant="h4" style={styles.title}>
          My Bankan Board
        </Typography>
        <Droppable droppableId="all-lists" direction="horizontal" type="list">
          {provided => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={styles.listContainer}
            >
              {lists.map((list, i) => (
                <BankanList
                  listId={list.id}
                  title={list.title}
                  cards={list.cards}
                  key={list.id}
                  index={i}
                />
              ))}
              {provided.placeholder}
              <ActionButton shouldAddList />
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};
const mapStateToProps = state => ({
  lists: state.lists
});
const styles = {
  listContainer: {
    display: "flex",
    marginRight: 10
  },
  title: {
    color: "rgb(255, 255, 255)",
    marginBottom: 12,
    marginLeft: 2,
    marginTop: 16
  }
};

export default connect(mapStateToProps)(App);
