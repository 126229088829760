import { CONSTANTS } from "../actions";

let listId = 4;
let cardId = 21;
const initialState = [
  {
    title: "About",
    id: "list-0",
    cards: [
      {
        id: "card-0",
        content:
          "Trello-inspired KANBAN Board\nReactJS Application...\n\nTry Throwing Stuff Around!\n(ノಠ益ಠ)ノ彡┻━┻ ヽ(´▽｀)ノ"
      },
      {
        id: "card-1",
        content: "By Abdelmalek Ihdene"
      },
      {
        id: "card-2",
        content:
          "GitLab Repository:\nhttps://www.gitlab.com/\nabdelmalek.ihdene/bankan"
      }
    ]
  },
  {
    title: "Features",
    id: "list-1",
    cards: [
      {
        id: "card-3",
        content: "Card/List Creation"
      },
      {
        id: "card-4",
        content: "Card/List Deletion"
      },
      {
        id: "card-5",
        content: "D&D Cards"
      },
      {
        id: "card-6",
        content: "D&D Lists"
      },
      {
        id: "card-7",
        content: "D&D Cards Across Lists"
      }
    ]
  },
  {
    title: "To-Do",
    id: "list-2",
    cards: [
      {
        id: "card-8",
        content: "Support for Links in Cards"
      },
      {
        id: "card-9",
        content: "Card/List Editing"
      },
      {
        id: "card-10",
        content: "Custom/Random Board Backgrounds"
      },
      {
        id: "card-11",
        content: "Card Creation Date, Description & Comments"
      },
      {
        id: "card-12",
        content: "Support for Multiple Boards"
      },
      {
        id: "card-13",
        content: "AWS Backend & DynamoDB Persistence"
      },
      {
        id: "card-14",
        content: "Authentication, Roles &\nUser Hierarchy"
      }
    ]
  },
  {
    title: "Technologies Used",
    id: "list-3",
    cards: [
      {
        id: "card-15",
        content: "HTML/CSS/JSX"
      },
      {
        id: "card-16",
        content: "NodeJS + NPM"
      },
      {
        id: "card-17",
        content: "React + Redux"
      },
      {
        id: "card-18",
        content: "React Beautiful D&D"
      },
      {
        id: "card-19",
        content: "Material-UI"
      },
      {
        id: "card-20",
        content: "Hosted on Netlify"
      }
    ]
  }
];
const listsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.ADD_CARD: {
      const newCard = {
        content: action.payload.content,
        id: `card-${cardId}`
      };
      cardId += 1;
      const newState = state.map(list => {
        if (list.id === action.payload.listId) {
          return { ...list, cards: [...list.cards, newCard] };
        } else {
          return list;
        }
      });
      return newState;
    }
    case CONSTANTS.DELETE_CARD: {
      const newState = state.map(list => {
        if (list.id === action.payload.listId) {
          const newCards = [...list.cards];
          newCards.splice(action.payload.index, 1);
          return { ...list, cards: newCards };
        } else {
          return list;
        }
      });
      return newState;
    }
    case CONSTANTS.DELETE_LIST: {
      return state.filter(list => list.id !== action.payload.listId);
    }
    case CONSTANTS.ADD_LIST:
      const newList = {
        title: action.payload.title,
        cards: [],
        id: `list-${listId}`
      };
      listId += 1;
      return [...state, newList];
    case CONSTANTS.DRAG_EVENT:
      const {
        droppableIdStart,
        droppableIdEnd,
        droppableIndexStart,
        droppableIndexEnd,
        // draggableId,
        type
      } = action.payload;
      const newState = [...state];
      if (type === "list") {
        const list = newState.splice(droppableIndexStart, 1);
        newState.splice(droppableIndexEnd, 0, ...list);
        return newState;
      }

      if (droppableIdStart === droppableIdEnd) {
        // Droppable in the same list
        const list = state.find(list => list.id === droppableIdStart);
        const card = list.cards.splice(droppableIndexStart, 1);
        list.cards.splice(droppableIndexEnd, 0, ...card);
      }
      // Droppable in other list
      if (droppableIdStart !== droppableIdEnd) {
        // Find the list where the drag started
        const listStart = state.find(list => droppableIdStart === list.id);
        // Pull the card out of said list
        const card = listStart.cards.splice(droppableIndexStart, 1);
        // Find the list where the drag ended
        const listEnd = state.find(list => droppableIdEnd === list.id);
        // Put the card into the new list
        listEnd.cards.splice(droppableIndexEnd, 0, ...card);
      }
      return newState;
    default:
      return state;
  }
};

export default listsReducer;
