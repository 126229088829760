import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import Icon from "@material-ui/core/Icon";
import React from "react";
import Typography from "@material-ui/core/Typography";

import { deleteCard } from "../actions";

const BankanCard = ({ dispatch, listId, id, index, content, lastChild }) => {
  return (
    <Draggable
      draggableId={String(id)}
      index={index}
      style={(_isDragging, draggableStyle) => ({
        ...draggableStyle,
        position: "static"
      })}
    >
      {provided => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div style={styles.cardContainer(lastChild)}>
            <Card elevation={0}>
              <CardContent style={styles.cardContent}>
                <Typography gutterBottom style={styles.typography}>
                  {content}
                </Typography>
                <Icon
                  fontSize="small"
                  color="secondary"
                  style={styles.deleteForeverIcon}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this card?"
                      )
                    ) {
                      dispatch(deleteCard(listId, index));
                    }
                  }}
                >
                  delete_outline
                </Icon>
              </CardContent>
            </Card>
          </div>
        </div>
      )}
    </Draggable>
  );
};
const styles = {
  cardContainer: lastChild => {
    return {
      boxSizing: "border-box",
      paddingBottom: 8
    };
  },
  cardContent: {
    display: "flex",
    padding: 12
  },
  deleteForeverIcon: {
    marginLeft: 2,
    alignSelf: "center",
    cursor: "pointer"
  },
  typography: {
    flexGrow: 1,
    marginBottom: 0,
    whiteSpace: "pre-line"
  }
};

export default connect()(BankanCard);
