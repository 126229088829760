import { connect } from "react-redux";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import React from "react";
import Typography from "@material-ui/core/Typography";

import "./BankanList.css";
import ActionButton from "./ActionButton";
import BankanCard from "./BankanCard";
import { deleteList } from "../actions";

const BankanList = ({ dispatch, listId, title, cards, index }) => {
  return (
    <Draggable draggableId={String(listId)} index={index}>
      {provided => (
        <Paper
          ref={provided.innerRef}
          className="paperContainer"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div style={styles.titleContainer}>
            <Typography variant="h6" style={styles.title}>
              {title}
            </Typography>
            <Icon
              fontSize="small"
              color="action"
              style={styles.closeIcon}
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to delete this list?")
                ) {
                  dispatch(deleteList(listId, index));
                }
              }}
            >
              close
            </Icon>
          </div>
          <Droppable droppableId={String(listId)}>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {cards.map((card, i, arr) => (
                  <BankanCard
                    listId={listId}
                    id={card.id}
                    content={card.content}
                    lastChild={i === arr.length - 1}
                    key={card.id}
                    index={i}
                  />
                ))}
                {provided.placeholder}
                <ActionButton shouldAddList={false} listId={listId} />
              </div>
            )}
          </Droppable>
        </Paper>
      )}
    </Draggable>
  );
};
const styles = {
  closeIcon: {
    cursor: "pointer",
    marginLeft: 2,
    marginTop: 6,
    whiteSpace: "nowrap"
  },
  title: {
    flexGrow: 1,
    marginBottom: 8,
    marginLeft: 2,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  titleContainer: {
    display: "flex"
  }
};

export default connect()(BankanList);
